<template>
    <el-row class="real-time" v-wechat-title="this.title">
        <div class="real-info">
            <div class="real-left">
                <div class="real-title">{{ realInfo.title }}</div>
                <div class="title-time">{{ realInfo.source }}&nbsp;&nbsp;&nbsp;&nbsp;{{ $moment(realInfo.createTime).format('YYYY/MM/DD') }}</div>
<!--                <div class="real-content1 base-content">-->
<!--                    随着组织选择云计算平台并将数据和应用程序迁移至混合云环境，他们要运用多个平台来协助分析不断翻新的数据类型，如果没有契合的策略和解决方案，可能会进一步增加业务用户查找、访问和使用企业数据时的复杂性，由此导致影响日常业务运营效率，乃至造成业务中断。-->
<!--                </div>-->
<!--                <div class="title-img">-->
<!--                    <img class="real-img" src="../../img/realtime/left-img1.png">-->
<!--                </div>-->
                <div class="base-content" v-html="realInfo.content"></div>
            </div>
            <div class="real-right">
                <div class="real-item" v-for="(item,idx) in realSource" :key="idx">
                    <img class="real-img" :src="item.imgUrl">
                    <div class="item-content" @click="infoReal(item)">{{ item.summary }}</div>
                    <div class="item-time">{{ item.source }}&nbsp;&nbsp;&nbsp;&nbsp;{{ $moment(realInfo.createTime).format('YYYY/MM/DD') }}</div>
                </div>
<!--                <div class="real-item">-->
<!--                    <img class="real-img" src="../../img/realtime/right-img2.png">-->
<!--                    <div class="item-content">Veritas正式启动Desktop and Laptop Option 90天免费试用</div>-->
<!--                    <div class="item-time">伊登软件&nbsp;&nbsp;&nbsp;&nbsp;2022/07/22</div>-->
<!--                </div>-->
<!--                <div class="real-item">-->
<!--                    <img class="real-img" src="../../img/realtime/right-img3.png">-->
<!--                    <div class="item-content">Veritas正式启动Desktop and Laptop Option 90天免费试用</div>-->
<!--                    <div class="item-time">伊登软件&nbsp;&nbsp;&nbsp;&nbsp;2022/07/22</div>-->
<!--                </div>-->
                <div class="more-real">查看更多资讯</div>
            </div>
        </div>
    </el-row>
</template>

<script>
import api from '@/api/realTime.js'
export default {
    name: "realTime",
    data() {
        return {
            title: '',
            noticeId: '',
            realInfo: {},
            realSource: []
        }
    },
    mounted() {
        this.noticeId = sessionStorage.getItem('noticeId');
        this.getRealInfo();
        this.listRealSource();
    },
    methods: {
        getRealInfo() {
            api.realInfo({
                noticeId: this.noticeId
            }).then(res => {
                this.realInfo = res.data;
                this.title = res.data.title + '- 伊登SaaS商城';
            })
        },
        listRealSource() {
            api.realSource({
                noticeId: this.noticeId
            }).then(res => {
                this.realSource = res.data;
            })
        },
        infoReal(val) {
            sessionStorage.setItem('noticeId', val.noticeId);
            this.$router.go(0);
        }
    }
}
</script>

<style scoped>
.real-time {
    font-size: 14px;
    width: 63%;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 16px;
}
.real-info {
    display: flex;
}
.real-img {
    width: 100%;
    height: 100%;
}
.real-left {
    width: 60%;
    padding: 5%;
    background: #FFFFFF;
}
.real-title {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
}
.title-time {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin: 3% 0%;
}
.base-content {
    font-size: 14px;
    color: #000000;
    text-indent: 5%;
    line-height: 28px;
}
.title-img {
    margin: 2% 0%;
}
.real-content1 {

}
.real-content2 {
    margin-top: 4%;
}
.real-img {
  width: 287px;
  height: 163px;
}
.real-right {
    width: 25%;
    margin-left: 1%;
}
.real-item {
    width: 286px;
    height: 266px;
    overflow: hidden;
    padding: 5%;
    background: #FFFFFF;
  margin-bottom: 12px;
}
.item-content {
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
  color: #000000;
    word-wrap:break-word;
    cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.item-content:hover {
    color: #F75145;
}
.item-time {
    font-size: 14px;
    color: #000000;
    line-height: 19px;
    margin-top: 5%;
    word-wrap:break-word;
}
.more-real {
    width: 286px;
    overflow: hidden;
    padding: 5%;
    background: #FFFFFF;
    font-size: 16px;
    color: #000000;
    line-height: 21px;
    text-align: center;
    cursor: pointer;
}
</style>
